/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
interface Iprops {
  color?: string;
  scale?: string;
  className?: string;
}

export default function InsuranceIcon({
  color = "currentColor",
  className,
  scale = "1",
  ...props
}: Iprops) {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.99447 2 2.84528 5.64128 2.07823 10.4074C2.07396 10.434 2.0694 10.4613 2.06473 10.4894C2.00853 10.8272 1.93462 11.2715 2.10868 11.7315C2.25162 12.1094 2.56019 12.4695 2.9125 12.6698C3.16663 12.8142 3.41927 12.8672 3.6471 12.8897C3.84447 12.9092 4.07224 12.9091 4.29459 12.9091L11.0865 12.9091V18.7273C11.0865 20.5008 12.4846 22 14.2838 22C16.0831 22 17.4811 20.5008 17.4811 18.7273C17.4811 18.2252 17.0721 17.8182 16.5676 17.8182C16.0631 17.8182 15.6541 18.2252 15.6541 18.7273C15.6541 19.5645 15.0071 20.1818 14.2838 20.1818C13.5605 20.1818 12.9135 19.5645 12.9135 18.7273V12.9091L19.7054 12.9091C19.9278 12.9091 20.1555 12.9092 20.3529 12.8897C20.5807 12.8672 20.8334 12.8142 21.0875 12.6698C21.4398 12.4696 21.7484 12.1094 21.8913 11.7315C22.0654 11.2714 21.9915 10.8272 21.9353 10.4894C21.9306 10.4613 21.926 10.434 21.9218 10.4074C21.1547 5.64128 17.0055 2 12 2Z"
        fill="#3B8DD4"
      />
    </svg>
  );
}
