import { atom } from "recoil";

export const offerRemainingRetryCountAtom = atom<number>({
  key: "cashLoan:offer:offerRemainingRetryCountAtom",
  default: 10
});

interface IInstallmentRow {
  dueDate: string; // "2021-11-15"
  installmentNumber: number; // 1
  interestAmount: number; // 16.67
  outstandingBalance: number; // 944.87
  paymentAmount: number; // 71.8
  principalAmount: number; // 55.13
}

export const installmentRowListAtom = atom<IInstallmentRow[] | null>({
  key: "cashLoan:offer:installmentRowListAtom",
  default: null
});

interface IalternativeRate {
  interestRate: number;
  maxTerm: number;
  minTerm: number;
  hasInterestRateAddition: boolean;
  interestRateAddition: number;
}

export type CreditType = "001" | "008" | "002" | "003" | "005" | "007" | "010";

export interface IrefinancingLoan {
  id: string;
  refinancingOptionId: string;
  contractReference: string;
  amount: number;
  dueAmount: number;
  nextInstallmentAmount: number;
  currency: string;
  interest: string;
  maturityDate: string;
  minTerm: number;
  alternativeRates: IalternativeRate[];
  totalRefinancingAmount: number;
  term: number;
  maxMonthlyPayment: number;
  minAmount: number;
  maxOfferAmount: number;
  assignedTerm: number;
  creditType: CreditType;
  startDate: string;
  isRefinanced: boolean;
  type?: "ABB" | "NON-ABB";
}

export interface IrefinancingOption {
  id: string;
  contractReference: string;
  refinancingOptionId: string;
  amount: number;
  dueAmount: number;
  nextInstallmentAmount: number;
  currency: string;
  interest: string;
  maturityDate: string;
  creditType: CreditType;
  isSelected?: boolean;
  issueDate?: string;
  startDate?: string;
  type?: "ABB" | "NON-ABB";
  company?: "abb" | "other";
}

export type TamCardCrossSaleOffer = {
  isOfferEmpty: boolean;
  refinancing: boolean;
  offerId: number;
  applicationId: string;
  productType: string;
  productCode: null;
  cardCode: string;
  amount: number;
  interestRate: number;
  maxMonthlyPayment: number;
  maxMonthlyPayment45Dti: number;
  monthlyPayment: number;
  currency: string;
  term: number;
  assignedLimit: number;
  maxOfferAmount: number;
  minOfferAmount: number;
  minAmount: number;
  cardDuration: number;
  offerStatus: string;
  minTerm: number;
  assignedTerm: number;
  commissionAmount: number;
  commissionRate: number;
  totalRefinancingAmount: number;
  amountAfterRefinancing: number;
  maxRefinancingAmount: number;
  minRefMonthlyPaymentAmount: number;
  alternativeRates: null;
  refinancingLoans: null;
  isMinimumTamkartOffer: boolean;
  titleImgUrl: string;
  cardPrice: number;
  isManualOffer: boolean;
  isLimitProlongation: boolean;
  isLimitIncrease: boolean;
  limitDuration: number;
  initMaxOfferAmount: null;
  amountWithRefinancing: null;
  maxRefinancingOfferAmount: null;
  updatedAmount?: number;
} | null;

type LimitAppDetails = {
  cardNumber: string;
  todLimit: number | null;
  todLimitEndDate: string | null;
  todLimitStartDate: string | null;
  currency: string;
  id: string;
};

export type LimitIncreaseCrossSaleOffer = {
  isOfferEmpty: boolean;
  refinancing: boolean;
  offerId: number;
  applicationId: string;
  productType: string;
  productCode: string | null;
  cardCode: string;
  amount: number;
  interestRate: number;
  maxMonthlyPayment: number;
  maxMonthlyPayment45Dti: number;
  monthlyPayment: number;
  currency: string;
  term: number;
  assignedLimit: number;
  maxOfferAmount: number;
  minOfferAmount: number;
  minAmount: number | null;
  cardDuration: number;
  offerStatus: string;
  minTerm: number | null;
  assignedTerm: number | null;
  commissionAmount: number;
  commissionRate: number;
  totalRefinancingAmount: number;
  amountAfterRefinancing: number | null;
  maxRefinancingAmount: number | null;
  minRefMonthlyPaymentAmount: number | null;
  alternativeRates: any | null;
  refinancingLoans: any | null;
  limitAppDetails: LimitAppDetails[];
  isMinimumTamkartOffer: boolean;
  titleImgUrl: string | null;
  cardPrice: number | null;
  isManualOffer: boolean | null;
  isLimitProlongation: boolean;
  isLimitIncrease: boolean;
  limitDuration: number;
  initMaxOfferAmount: number | null;
  amountWithRefinancing: number | null;
  maxRefinancingOfferAmount: number | null;
  cardMediumImageUrl: string;
};

export interface IofferData {
  offerId: string;
  limitDuration: number;
  productType: "CASH" | "TAMKART";
  productCode: string;
  cardCode: string;
  amount: number;
  interestRate: number;
  maxMonthlyPayment: number;
  monthlyPayment: number;
  maxOfferAmount: number;
  currency: string;
  term: number;
  assignedLimit: number;
  minAmount: number;
  cardDuration: string;
  minTerm: number;
  assignedTerm: number;
  commissionAmount: string;
  totalRefinancingAmount: number;
  maxRefinancingAmount: number;
  minRefMonthlyPaymentAmount: number;
  alternativeRates: IalternativeRate[];
  refinancingLoans: IrefinancingLoan[];
  recalculatedWithSlider: boolean;
  refinancingOptions: IrefinancingOption[];
  alternativeOffers:
    | (IofferData & { initMaxOfferAmount: number; refinancing: boolean })
    | null;
  refinancingOptionsAvailable: boolean;
  maxMonthlyPayment45Dti: number;
  minOfferAmount: number;
  maxRefinancingOfferAmount: number;
  amountAfterRefinancing: number;
  amountWithRefinancing: number;
  refinancing: boolean;
  tamCardCrossSaleOffer?: TamCardCrossSaleOffer;
  amountWithInsurance: number;
  limitAppDetails?: LimitAppDetails[];
  limitIncreaseCrossSaleOffers?: LimitIncreaseCrossSaleOffer[];
  imagePath?: string;
  crossSellSelectedCard?: string;
  insuranceAmount?: number;
  customerInfo?: {
    birthDate: string;
    firstName: string;
    gender: string;
    lastName: string;
    patronymic: string;
  };
}

export const offerDataAtom = atom<IofferData | null>({
  key: "cashLoan:offer:offerDataAtom",
  // default: null,
  default: {
    offerId: "string",
    productType: "CASH",
    productCode: "string",
    cardCode: "string",
    amount: 7500,
    recalculatedWithSlider: false,
    interestRate: 16.9,
    maxMonthlyPayment: 1969.56,
    monthlyPayment: 300,
    maxOfferAmount: 7500,
    currency: "AZN",
    term: 48,
    assignedLimit: 7500,
    minAmount: 300,
    cardDuration: "",
    minTerm: 3,
    assignedTerm: 48,
    commissionAmount: "0",
    totalRefinancingAmount: 0,
    maxRefinancingAmount: 50000,
    minRefMonthlyPaymentAmount: 0,
    alternativeOffers: null,
    refinancingOptionsAvailable: false,
    maxMonthlyPayment45Dti: 900,
    maxRefinancingOfferAmount: 10000,
    minOfferAmount: 0,
    limitDuration: 2,
    amountAfterRefinancing: 100,
    amountWithRefinancing: 100,
    refinancingOptions: [],
    amountWithInsurance: 0,
    refinancing: true,
    alternativeRates: [
      {
        interestRate: 13.9,
        minTerm: 3,
        maxTerm: 12,
        hasInterestRateAddition: true,
        interestRateAddition: 2
      },
      {
        interestRate: 14.9,
        minTerm: 13,
        maxTerm: 24,
        hasInterestRateAddition: true,
        interestRateAddition: 2
      },
      {
        interestRate: 15.9,
        minTerm: 25,
        maxTerm: 36,
        hasInterestRateAddition: true,
        interestRateAddition: 2
      },
      {
        interestRate: 16.9,
        minTerm: 37,
        maxTerm: 48,
        hasInterestRateAddition: true,
        interestRateAddition: 2
      }
    ],
    refinancingLoans: [
      {
        totalRefinancingAmount: 0,
        isRefinanced: false,
        term: 59,
        maxMonthlyPayment: 2000,
        minAmount: 500,
        maxOfferAmount: 30000,
        assignedTerm: 59,
        amount: 10,
        minTerm: 23,
        creditType: "001",
        // applicationId: '24162',
        contractReference: "206DGSL220010003",
        // createdDate: '2022-05-13 00:00:00',
        currency: "AZN",
        // currentNumberOfOverdueDays: 101,
        dueAmount: 8090.18,
        id: "406",
        interest: "16.9",
        // isAmountTransfered: false,
        // isRefinanced: false,
        // isSelected: false,
        // latestDueAmount: null,
        maturityDate: "01.01.2025",
        nextInstallmentAmount: 288.03,
        // offerId: 5560,
        // productAccount: '33801019442828881206',
        // productCategory: 'YENI_MUSHTERI',
        // productCode: 'DGSL',
        // productId: null,
        refinancingOptionId: "",
        // startDate: '01.01.2021',
        // term: 48,
        // totalNumberOfOverdueDays: 0,
        startDate: "",
        alternativeRates: [
          {
            interestRate: 13.9,
            minTerm: 12,
            maxTerm: 24,
            hasInterestRateAddition: true,
            interestRateAddition: 2
          },
          {
            interestRate: 14.9,
            minTerm: 25,
            maxTerm: 36,
            hasInterestRateAddition: true,
            interestRateAddition: 2
          },
          {
            interestRate: 15.9,
            minTerm: 37,
            maxTerm: 48,
            hasInterestRateAddition: true,
            interestRateAddition: 2
          },
          {
            interestRate: 16.9,
            minTerm: 49,
            maxTerm: 59,
            hasInterestRateAddition: true,
            interestRateAddition: 2
          }
        ]
      }
    ],
    tamCardCrossSaleOffer: null
  }
});

export const refinancingOptionsAtom = atom<IrefinancingOption[]>({
  key: "cashLoan:offer:refinancingOptionsAtom",
  default: [
    {
      id: "001",
      amount: 7000,
      dueAmount: 4352.5,
      interest: "20",
      contractReference: "N5534530K88",
      maturityDate: "12.01.2026",
      issueDate: "16.01.2023",
      nextInstallmentAmount: 256.24,
      currency: "AZN",
      isSelected: true,
      type: "ABB",
      company: "other",
      creditType: "001",
      refinancingOptionId: "1",
      startDate: ""
    },
    {
      id: "002",
      amount: 4500,
      dueAmount: 2850.89,
      interest: "19",
      contractReference: "N5534530K88",
      issueDate: "14.02.2021",
      maturityDate: "14.02.2024",
      nextInstallmentAmount: 220.12,
      currency: "AZN",
      isSelected: true,
      type: "ABB",
      company: "other",
      refinancingOptionId: "1",
      creditType: "001",
      startDate: ""
    },
    {
      id: "003",
      amount: 5000,
      dueAmount: 2800.65,
      interest: "19",
      contractReference: "N5534530K88",
      issueDate: "20.05.2022",
      maturityDate: "20.05.2025",
      nextInstallmentAmount: 102.7,
      currency: "AZN",
      isSelected: true,
      type: "NON-ABB",
      refinancingOptionId: "1",
      company: "other",
      creditType: "001",
      startDate: ""
    },
    {
      id: "004",
      amount: 500,
      dueAmount: 300.21,
      interest: "19",
      contractReference: "N5534530K88",
      issueDate: "20.05.2022",
      maturityDate: "20.05.2025",
      nextInstallmentAmount: 19.62,
      currency: "AZN",
      isSelected: true,
      type: "ABB",
      refinancingOptionId: "1",
      startDate: "",
      creditType: "001",
      company: "other"
    },
    {
      id: "005",
      amount: 7500,
      dueAmount: 7000.44,
      interest: "19",
      contractReference: "N5534530K88",
      issueDate: "20.05.2022",
      maturityDate: "20.05.2025",
      nextInstallmentAmount: 275,
      currency: "AZN",
      isSelected: true,
      type: "NON-ABB",
      refinancingOptionId: "1",
      startDate: "",
      company: "abb",
      creditType: "001"
    }
  ]
});

export const interestRateAtom = atom<number>({
  key: "cashLoan:offer:interestRateAtom",
  default: 0
});

export default {
  "cashLoan:offer:offerRemainingRetryCountAtom": offerRemainingRetryCountAtom,
  "cashLoan:offer:offerDataAtom": offerDataAtom,
  "cashLoan:offer:refinancingOptionsAtom": refinancingOptionsAtom,
  "cashLoan:offer:interestRateAtom": interestRateAtom,
  "cashLoan:offer:installmentRowListAtom": installmentRowListAtom
};
